* {
	font-family: roboto, sans-serif;
}

html,
body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
	overflow-y: auto;
	scrollbar-color: #FF5E5B transparent;
	scrollbar-width: thin !important;

	&::-webkit-scrollbar-corner {
		background: transparent
	}

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar:horizontal {
		height: 8px
	}

	/* Track */
	&::-webkit-scrollbar-track:horizontal {
		box-shadow: inset 0 0 5px transparent;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb:horizontal {
		background: grey;
		border-radius: 5px;

	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:horizontal:hover {
		background: grey;
	}

	/* Track */
	&::-webkit-scrollbar-track:vertical {
		box-shadow: inset 0 0 5px transparent;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb:vertical {
		background: #FF5E5B;
		border-radius: 5px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:vertical:hover {
		background: #b30000;
	}
}